@import "antd/dist/antd.css";
@import "~video-react/styles/scss/video-react";

@import "./utils.scss";
@import "./login.scss";


$primary-color: #2440f1;
$primary-color-light: #3d55f0;

$link-color: #2440f1;

$secondarylink_color: #a5aab4;

$background-color: #fcfcfc;
$secondary-text-color: #8E8993;

body, .body {
  font-family: Lato, sans-serif;
}

a {
  color: $primary-color;
}

a:hover {
  text-decoration: underline;
  color: $primary-color-light;
}

.text-primary {
  color: $primary-color;
}

.no-linkify,
.no-linkify:hover {
  color: black;
  text-decoration: none;
}

.no-linkify-primary,
.no-linkify-primary:hover {
  color: $primary-color;
  text-decoration: none;
}

.p-color {
  color: $primary-color;
}

.bg-highlight {
  background-color: rgba(36, 64, 241, 0.1);
}

.ant-tag {
  border-radius: 12px;
}

.logo {
  margin: 24px 0 80px;
}

.site-layout-background,
.wmde-markdown,
.site-layout-background .wmde-markdown {
  background-color: $background-color;
}

.mobile .wmde-markdown {
  background-color: #FFFFFF;
}

.content-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
}

.content-header-avatar {
  margin-right: 8px;
}

.desktop-page-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: $primary-color;
}

.card-frame {
  background: #fff;
  box-shadow: 0 0 24px rgba(42 35 48 / 3%);
  border-radius: 32px;
  padding: 24px;
}

/* Fix for broken antd menu */
.ant-menu-item-active .ant-menu-title-content {
  color: $primary-color;
}

.ant-menu-inline-collapsed .ant-menu-title-content {
  display: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-color;
}

.adm-toast-mask .adm-toast-main {
  word-break: break-word;
  text-align: center;
}

.ant-btn:hover {
  color: $primary-color;
  border-color: $primary-color;
}

.ant-modal-content,
.ant-modal-header,
.ant-popover-inner,
.ant-picker {
  border-radius: 8px;
}

.ant-btn-primary:focus {
  color: white;
  background-color: $primary-color;
  border-color: $primary-color;
}

.adm-toast-main .adm-auto-center-content {
  white-space: nowrap;
}

.ant-menu-item {
  margin-left: 10px;
}

.ant-typography a {
  color: $primary-color;
}

.ant-typography a:hover {
  color: $primary-color-light;
}

.ant-menu-inline-collapsed .ant-menu-item {
  text-align: center;
  margin-left: 0;
}

.ant-layout-footer {
  background-color: $background-color;
  padding: 8px 50px;
}

a.ant-typography {
  color: $primary-color;
}

.ant-btn-primary {
  background-color: $primary-color;
}

.ant-btn-primary:hover {
  background-color: $primary-color-light;
}

.ant-input-affix-wrapper,
#root .ant-select .ant-select-selector {
  border-radius: 8px;
}

.ant-input, .ant-input-number {
  border-radius: 8px;
}

.ant-progress-bg {
  background-color: $primary-color;
}

.ant-menu-item .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: $primary-color;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom: 2px solid $primary-color;
}

.ant-message-notice-content,
.mobile .ant-message-notice-content {
  border-radius: 12px;
}

.filepond--credits {
  display: none;
}

.filepond--drop-label {
  background: #f9fafb;
  border: 1px dashed #d8dbdf;
  box-sizing: border-box;
  border-radius: 8px;
}

.circled-icon {
  padding: 12px;
  border-radius: 50%;
  background: rgb(36, 64, 241, 0.05);
  text-align: center;
  display: inline-block;
  margin-right: 40px;
}

/* Course List */

.courses-list-item {
  padding: 12px;
  margin: 20px 0;
  height: 104px;
  background: #fff;
  border: 1px solid rgba(36 64 241 / 50%);
  box-sizing: border-box;
  box-shadow: 0 0 24px rgba(42 35 48 / 3%);
  border-radius: 12px;
}

.courses-list-img {
  width: 100px;
  border-radius: 8px;
}

.courses-list-item-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding: 24px 16px;
}

.courses-status-pill {
  border-radius: 8px;
  width: 109px;
  height: 28px;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.courses-status-pill-text {
  font-size: 16px;
  line-height: 28px;
  opacity: 1;
  font-weight: normal;
}

.courses-status-pill-draft {
  background: rgba(242 153 74 / 10%);
  color: #f2994a;
}

.courses-status-pill-published {
  background: rgba(11 167 55 / 10%);
  color: #0ba737;
}

.courses-status-pill-archived {
  background: rgba(120 123 142 / 10%);
  color: #787b8e;
}

.courses-list-item-menu {
  float: right;
  margin: 23px 40px;
  border: solid 2px $primary-color;
  border-radius: 50%;
  padding: 2px;
}

.courses-list-item-menu:hover {
  cursor: pointer;
}

.course-list-button-bar {
  margin-bottom: 40px;
}

.courses-list-item-info {
  margin-top: 12px;
  position: relative;
}

.courses-list-item-info-icon {
  position: absolute;
  top: 0;
}

.courses-list-item-info-text {
  padding-left: 8px;
  line-height: 24px;
  font-size: 16px;
  margin-left: 24px;
}

/* Edit Course Flow */

.edit-course-goback,
.view-course-goback {
  background: #fff;
  padding: 24px 24px 24px 8px;
  border-right: 1px solid #f5f4f7;
}

.edit-course-goback a {
  color: $primary-color;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.edit-course-title,
.view-course-title {
  background: #fff;
  padding: 20px 0 20px 60px;
}

.edit-course-top-row,
.view-course-top-row {
  background: #fff;
}

.edit-course-leftnav {
  background: #fff;
  position: relative;
  width: 100%;
  padding: 60px 0 0 0;
}

.edit-course-nav-header {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $primary-color;
}

.edit-course-course-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: black;
}

.edit-course-left-nav-sections {
  max-height: calc(100vh - 305px);
  overflow: scroll;
}

.edit-course-section-card {
  background: #fff;
  border: 1px solid #f5f4f7;
  box-sizing: border-box;
  box-shadow: 0px 0px 24px rgba(42 35 48 / 3%);
  border-radius: 12px;
  margin: 0 24px 24px 0;
  width: 100%;
  padding: 24px 20px;
  position: relative;
}

.edit-course-section-card-reordering-overlay {
  width: 100%;
  height: 100%;
  position:absolute;
  top: 0;
  left: 0;
  z-index: 3000;
  visibility: hidden;
  opacity: 0.2;
  background: #8E8993;border-radius: 12px;
}

.edit-course-section-card-reordering-overlay.loading {
  visibility: visible;
}

// .edit-course-drag-menu-icon:hover {
//   cursor: grab;
// }

.edit-course-page-card {
  background: #fff;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 24px 24px 24px 0;
  width: 100%;
  padding: 16px 12px;
}

.edit-course-nav-inactive,
.edit-course-nav-active {
  padding: 20px 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  border-bottom: solid 1px rgba(172 189 201 / 60%);
}

.edit-course-nav-inactive {
  color: #787b8e;
  background: #fff;
}

.edit-course-nav-active {
  background: rgba(36 64 241 / 8%);
  color: $primary-color;
}

.edit-course-settings-card {
  background: #fff;
  box-shadow: 0px 0px 24px rgba(42 35 48 / 3%);
  border-radius: 32px;
}

.edit-course-settings-leftcol {
  border-right: solid 1px rgba(172 189 201 / 60%);
}

.edit-course-image-root {
  background: #f9fafb;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 100%;
  margin: 24px;
}

.edit-course-glossary-entry-box {
  background: #f9fafb;
  border: 1px solid #d8dbdf;
  box-sizing: border-box;
  border-radius: 8px;
}

.w-md-editor .w-md-editor-show-live {
  border-radius: 8px;
}

.edit-courses-add-elements-row {
  border-top: solid 1px #cdd7df;
  color: $primary-color;
  font-size: 16px;
}

.edit-courses-add-elements-button {
  cursor: pointer;
  padding-right: 40px;
}

.edit-courses-quiz-menu .ant-menu-horizontal {
  line-height: 33px;
}

.edit-courses-questions-box,
.edit-courses-ftbg-box {
  background: #f9fafb;
  border: 1px solid #d8dbdf;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  margin-top: 24px;
}

.edit-course-ftbg-word-pill {
  background: rgba(120, 123, 142, 0.1);
  border: 1px solid $primary-color;
  box-sizing: border-box;
  border-radius: 20px;
  margin-right: 8px;
  color: #787b8e;
  height: 40px;
  padding: 8px;
  display: inline-block;
  font-weight: bold;
  display: inline-block;
  margin-top: 4px;
}

.edit-course-ftbg-word-pill.active {
  color: $primary-color;
  border-color: $primary-color;
  background-color: #dcdfef;
}

.edit-video-subtitle-upload .filepond--root .filepond--hopper {
  height: 40px;
}

.edit-video-subtitle-upload .filepond--root .filepond--drop-label {
  min-height: 40px;
}

.view-course-leftnav {
  background: white;
  // padding: 24px;
  min-height: calc(100vh - 134px);
}

.view-course-leftnav-summary-card {
  background: #ffffff;
  border: 1px solid #f5f4f7;
  box-sizing: border-box;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
  padding: 24px;
  margin: 24px 24px 12px 24px;
}

.view-course-leftnav-section {
  padding-bottom: 24px;
  padding-top: 12px;
  border-bottom: solid 1px rgba(172, 189, 201, 0.6);
  position: relative;
}

.view-course-leftnav-page {
  padding: 16px;
  background: #f9fafb;
  border-radius: 12px;
  margin-top: 8px;
}

.view-course-leftnav-page.active {
  background: #dadfff;
}

.view-course-leftnav-page.completed {
  background: rgb(11, 167, 55, 0.1);
}

.view-course-leftnav-page-checkmark {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  color: #0ba737;
}

.view-course-page-element {
  margin-top: 24px;
}

.view-course-ftbg-game,
.view-course-rtsg-game {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
  min-width: 720px;
}

.m-view-course-ftbg-game,
.m-view-course-rtsg-game {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
}

.view-course-ftbg-blank-spot {
  background: #ffffff;
  border: 1px solid #2440f1;
  box-sizing: border-box;
  border-radius: 45px;
  display: inline-block;
  margin: 0 4px;
  padding: 8px;
}

.view-course-ftbg-blank-spot-hidden {
  visibility: hidden;
}

.view-course-ftbg-fill-word {
  box-sizing: border-box;
  border-radius: 45px;
  display: inline-block;
  margin: 0 4px;
  padding: 8px;
  background: rgba(36, 64, 241, 0.1);
  transform: translate(0, 0);
}
.view-course-ftbg-fill-word.valid-word {
  background: rgba(36, 241, 87, 0.1);
}
.view-course-ftbg-fill-word.invalid-word {
  background: rgba(241, 36, 36, 0.1);
}

.m-view-course-ftbg-fill-word {
  box-sizing: border-box;
  border-radius: 45px;
  display: inline-block;
  margin: 0 4px;
  padding: 8px;
  background: rgba(36, 64, 241, 0.1);
  transform: translate(0, 0);
}

.view-course-quiz-root {
  background: white;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
  padding: 24px;
}

.view-course-quiz-timer-overlay {
  background: white;
  // opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  padding: 24px;
  border-radius: 12px;
}

.view-course-glossary-entry,
.view-course-notes-entry {
  padding: 24px 0;
  border-bottom: solid 1px rgba(172, 189, 201);
}

.account-left-nav {
  width: 100%;
  padding-top: 136px;
  height: calc(100vh - 38px);
  background-color: white;
}

.accounts-reset-password-card,
.accounts-personal-info-card {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 32px;
  padding: 40px;
}

.accounts-teachers-row,
.students-student-row {
  background: #ffffff;
  border: 1px solid rgba(36, 64, 241, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
}

.student-card {
  background: #ffffff;
  border: 1px solid #f5f4f7;
  box-sizing: border-box;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 8px;
  padding: 20px;
}

.student-course-card {
  background: #ffffff;
  border: 1px solid #f5f4f7;
  box-sizing: border-box;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
  padding: 20px;
}

.student-course-card.active {
  background: #dadfff;
}

.student-courses-list-img {
  width: 80px;
  border-radius: 8px;
}

.thread {
  margin-top: 20px;
  padding-bottom: 20px;

  border-bottom: solid 1px #f5f4f7;
}

.thread.thread-last {
  border-bottom: none;
}

.messages-send-button {
  display: inline-block;
  padding-left: 8px;
  padding-top: 1px;
  font-size: 20px;
  color: white;
  background-color: $primary-color;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.view-course-questions-card {
  background: #ffffff;
  border: 1px solid rgba(36, 64, 241, 0.1);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 24px;
  margin-top: 40px;
}

.header-popover-row {
  border-top: solid 1px rgba(172, 189, 201, 0.6);
}

.student-course-list-card {
  background: rgba(36, 64, 241, 0.1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03),
    0px 24px 16px -16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 24px;
}

.student-course-list-card-img {
  display: inline-block;
  height: 160px;
}

.student-course-list-card-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.student-course-list-card-img img {
  flex-shrink: 0;
  width:100%;
  height:100%;
  object-fit: cover;
  height: 160px;
  border-radius: 8px;
}

// Mobile
.mobile {
  position: relative;
}

.m-body {
  // screen height minus footer minus a space for the URL bar on mobile browsers
  min-height: calc(100vh - 32px - 32px);
}

.m-footer {
  // width: 100%;
  height: 32px;
  padding-top: 8px;
}

// .mobile .adm-button-primary {
//   background-color: $primary-color;
// }

.mobile {
  --adm-color-primary: #2440f1;
}

.mobile .adm-input-element, .mobile select, .mobile .adm-text-area-element {
  border: solid 1px rgba(172, 189, 201, 0.6);
  border-radius: 8px;
  padding: 8px;
}

.mobile .adm-input-element,
.mobile select {
  height: 44px;
}

.m-navbar-title {
  color: $primary-color;
  font-weight: bold;
}

.m-menu {
  border-top: solid 1px rgba(245, 244, 247, 0.6);
}

.m-menu-item {
  margin-top: 32px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 22px;
  height: 24px;
  font-weight: bold;
  position: relative;
}

.m-menu-item a {
  color: #252840;
  text-decoration: none;
}

.m-notif-icon {
  padding: 6px 6px;
  border-radius: 50%;
  background-color: rgb(34, 62, 241, 0.05);
  width: 36px;
  height: 36px;
}

.m-two-pill-switcher {
  width: 300px;
  height: 50px;
  border-radius: 50px;
  background: rgb(36, 64, 241, 0.1);
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
}

.m-two-pill-switcher-pill {
  width: 146px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;  
  text-align: center;
  color: black;
  padding: 10px 0;
  display: inline-block;
}

.m-two-pill-switcher .m-two-pill-switcher-active-pill {
  border-radius: 50px;
  background: #2440f1;
  color: white;
}

.m-student-course-card {
  position: relative;
  width: 100%;
  min-height: 235px;
  color: white;
  border-radius: 20px;
}

.m-student-course-card-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.m-course-image-photo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.m-student-course-card-bg, .m-student-course-card-contents {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.m-student-course-card-contents {
  width: 100%;
  min-height: 235px;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  padding: 16px;
}

.m-student-course-card-contents h2 {
  color: white;
}

.m-own-message-bubble, .m-other-message-bubble {
  padding: 12px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 12px;
}

.m-own-message-bubble {
  background-color: $primary-color;
  color: white;
}

.m-other-message-bubble {
  background-color: rgb(120, 123, 142, 0.1);
  color: #252840;
}

.m-course-nav-course-card {
  width: 100%;
  padding: 16px 24px;
  background: #FFFFFF;
  border: 1px solid #F5F4F7;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
}

.m-view-course-nav-page {
  padding: 16px;
  background: #f9fafb;
  border-radius: 12px;
  margin-top: 8px;
}

.m-view-course-nav-page.active {
  background: #dadfff;
}

.m-view-course-nav-page.completed {
  background: rgb(11, 167, 55, 0.1);
}

body .datepicker.default .datepicker-header,
body .datepicker.default .datepicker-navbar-btn {
  color: $primary-color;
}
body .datepicker.default .datepicker-wheel {
  border-top: 1px solid $primary-color;
  border-bottom: 1px solid $primary-color;
}

.notification, .message-nav-thread-row {

  border-bottom: solid 1px rgba(245, 244, 247, 0.6);
}
.message-nav-thread-row-active {
  background-color: #dadfff;
}

.adm-button.adm-button-mini {
  font-size: 12px;
}

.messages-leftnav {
  background: #FFFFFF;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 12px;
}
.landing-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.landing-left-image-container {
  display: inline-block;
  width: 50%;
  height: 100vh;
}

.landing-left-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.landing-left-image img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  text-align: center;
}

.landing-body {
  margin: 0;
  padding: 0;
  width: 100vw;
}

.landing-right-container {
  width: 450px;
  margin: 0 auto;
}

.landing-page-card {
  padding: 24px;
  width: 100%;
  margin: 24px 24px 40px 24px;
  background: #FFFFFF;
  border: 1px solid #F5F4F7;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 32px;
}

.landing-page-metric-card {
  padding: 24px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #F5F4F7;
  box-shadow: 0px 0px 24px rgba(42, 35, 48, 0.03);
  border-radius: 32px;
}

.landing-page-pill,
.metrics-pill {
  background: #E7F2FF;
  border-radius: 50px;
  font-weight: normal;
  color: $primary-color;
  margin-left: 24px;
  padding: 4px 8px;
  cursor: pointer;
}

.landing-page-pill {
  float: right;
}

.landing-page-pill.active,
.metrics-pill.active {
  background: $primary-color;
  color: white;
  font-weight: bolder;
}

.hover-bg-color:hover {
  box-shadow: 0 0 24px rgba(42 35 48 / 20%);
}

.m-textarea {
  border-radius: 8px;
  border: 1px solid #d8dbdf;
  padding: 8px;
}

.m-signup-password {
  display: flex;
  align-items: center;

  .input {
    flex: auto;
  }

  .eye {
    flex: none;
    margin-left: 8px;
    padding: 4px;
    cursor: pointer;

    svg {
      display: block;
      font-size: var(--adm-font-size-7);
    }
  }
}
