.login-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.login-left-image-container {
  display: inline-block;
  width: 50%;
  height: 100vh;
}

.login-left-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-left-image img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
}

.login-body {
  margin: 0;
  padding: 0;
  width: 100vw;
}

.login-right-container {
  width: 400px;
  margin: 0 auto;
}
.scholarship-container {
  width: 600px;
  margin: 0 auto;
}
