/* Margins: m-<a/t/b/l/r>-<auto/4/8/12/20/24/60> */
$secondary-text-color: #8E8993;
$danger-text-color: #ff4d4f;
$success-text-color: #0BA737;
$warning-text-color: #dfca11;

.m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-h-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.m-a-0 {
  margin: 0;
}

.m-a-4 {
  margin: 4px;
}

.m-a-8 {
  margin: 8px;
}

.m-a-12 {
  margin: 12px;
}

.m-a-20 {
  margin: 20px;
}

.m-a-24 {
  margin: 24px;
}

.m-a-40 {
  margin: 40px;
}

.m-a-60 {
  margin: 60px;
}

.m-a-auto {
  margin: auto;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-120 {
  margin-top: 120px;
}

.m-t-auto {
  margin-top: auto;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-b-4 {
  margin-bottom: 4px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-b-auto {
  margin-bottom: auto;
}

.m-l-0 {
  margin-left: 0;
}

.m-l-4 {
  margin-left: 4px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-0 {
  margin-right: 0;
}

.m-r-4 {
  margin-right: 4px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-60 {
  margin-right: 60px;
}

.m-r-auto {
  margin-right: auto;
}

/* Padding */
.p-h-auto {
  padding-left: auto;
  padding-right: auto;
}

.p-a-4 {
  padding: 4px;
}

.p-a-8 {
  padding: 8px;
}

.p-a-12 {
  padding: 12px;
}

.p-a-20 {
  padding: 20px;
}

.p-a-24 {
  padding: 24px;
}

.p-a-32 {
  padding: 32px;
}

.p-a-40 {
  padding: 40px;
}

.p-a-60 {
  padding: 60px;
}

.p-a-auto {
  padding: auto;
}

.p-t-4 {
  padding-top: 4px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-t-18 {
  padding-top: 18px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-t-auto {
  padding-top: auto;
}

.p-b-4 {
  padding-bottom: 4px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-b-12 {
  padding-bottom: 12px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-b-auto {
  padding-bottom: auto;
}

.p-l-4 {
  padding-left: 4px;
}

.p-l-8 {
  padding-left: 8px;
}

.p-l-12 {
  padding-left: 12px;
}

.p-l-16 {
  padding-left: 16px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-24 {
  padding-left: 24px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-60 {
  padding-left: 60px;
}

.p-l-auto {
  padding-left: auto;
}

.p-r-4 {
  padding-right: 4px;
}

.p-r-8 {
  padding-right: 8px;
}

.p-r-12 {
  padding-right: 12px;
}

.p-r-16 {
  padding-right: 16px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-24 {
  padding-right: 24px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-60 {
  padding-right: 60px;
}

/* Alignment */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-secondary,
.text-secondary a {
  color: $secondary-text-color;
}

.text-danger {
  color: $danger-text-color;
}

.text-success {
  color: $success-text-color;
}

.text-warning {
  color: $warning-text-color;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-small, .text-small .wmde-markdown {
  font-size: 12px;
}

.text-medium {
  font-size: 14px;
}

.text-large {
  font-size: 16px;
}

.text-xlarge {
  font-size: 20px;
}

.text-xxlarge {
  font-size: 22px;
}

.text-xxxlarge {
  font-size: 36px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.width-100 {
  width: 100%;
}

.clickable:hover {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.v-hidden {
  visibility: hidden,
}

.center-div {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.float-right {
  float: right;
}

.width-100 {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.h-100 {
  height: 100%;
}

.bg-white {
  background: white;
}

.draggable {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.b-r-12 {
  border-radius: 12px;
}

 // Mobile

 .mobile .m-input-large {
  height: 44px;
  border: 1px solid rgba(172, 189, 201, 0.6);
  border-radius: 8px;
  flex: auto;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 8px;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  background: transparent;
  min-height: 1.5em;
 }

 .warning-card {
   background-color: rgba(255, 230, 0, 0.4);
   color: white;
   border-radius: 12px;
   padding: 8px;
 }

 .b-b-1 {
  border-bottom: solid 1px $secondary-text-color;
 }
